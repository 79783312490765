import React, { useEffect } from "react";
import "./App.css";
import { createHashRouter, Navigate, RouterProvider } from "react-router-dom";
import Layout from "./Common/Layout";
import HomePage from "./Pages/HomePage/HomePage";
import AboutPage from "./Pages/AboutPage/AboutPage";
import LoginPage from "./Pages/LoginPage/LoginPage";
// import { useSelector } from 'react-redux';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Collapse, Dropdown, Ripple, initTE } from "tw-elements";
import { useSelector } from "react-redux";
import ForgotPassword from "Pages/LoginPage/ForgotPassword";
import UpdatePassword from "Pages/LoginPage/UpdatePassword";

initTE({ Dropdown, Ripple });
const RequireAuth = ({ children }) => {
  let token = useSelector((state) => state.userAuth.userinfo.token);
  if (!token) {
    return <Navigate to="/login" />;
  }
  return children;
};
const router = createHashRouter([
  {
    path: "/",
    // element: <Layout />,
    element: (
      <RequireAuth>
        <Layout />
      </RequireAuth>
    ),
    children: [
      {
        path: "",
        element: <HomePage />,
      },
    ],
  },
  {
    path: "forgot-password",
    element: <ForgotPassword />,
  },
  {
    path: "update-password",
    element: <UpdatePassword />,
  },
  {
    path: "about",
    element: <AboutPage />,
  },
  {
    path: "/login",
    element: <LoginPage />,
  },
]);
function App() {
  useEffect(() => {
    initTE({ Dropdown });
    const interval = setInterval(() => {
      initTE({ Collapse });
    }, 2000);
    return () => {
      clearInterval(interval);
    };
  }, []);
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />

      <div>
        <RouterProvider router={router} />
      </div>
    </>
  );
}

export default App;
