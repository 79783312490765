import React from "react";
import { FaHandHoldingMedical } from "react-icons/fa";
import { MdSpaceDashboard } from "react-icons/md";
const Claim = React.lazy(() => import(/* webpackChunkName: "Claim" */ "./AllCharges/Claim/Claim"));
const InstitutionalClaim = React.lazy(() => import(/* webpackChunkName: "Claim" */ "./AllCharges/Claim/InstitutionalClaim"));
const ChargesSearch = React.lazy(() => import(/* webpackChunkName: "ChargesSearch" */ "./ChargesSearch/ChargesSearch"));

export const ChargesTabsComponent = {
    ChargesSearch,
    Claim,
    InstitutionalClaim
};
const ChargesTabsObj = () => {
    return [
        {
            title: "Search",
            Component: "ChargesSearch",
            icon: MdSpaceDashboard,
            right: true,
            eventKey: 68, // press d from keyboard
        },
        {
            title: "Claim",
            Component: "Claim",
            icon: FaHandHoldingMedical,
            right: true,
            eventKey: 68, // press d from keyboard
        },
        {
            title: "Institutional Visit",
            Component: "InstitutionalClaim",
            icon: FaHandHoldingMedical,
            right: true,
            eventKey: 68, // press d from keyboard
        },
    ];
};

export default ChargesTabsObj;
