import { createSelector } from "@reduxjs/toolkit";

export const tabsSelector = (state) => state.tabs;
export const submissionTabsSelector = createSelector([tabsSelector], (tabs) => tabs.submission)

export const ES_DataSelector = createSelector([submissionTabsSelector], (tabs) => tabs.find((tab) => tab.active && tab.name === "submission"))


export const sidebarSelector = createSelector([tabsSelector], (tabs) => tabs.sidebar)



// patients Selectors
export const patientTabsSelector = createSelector([tabsSelector], (tabs) => tabs.patients)
export const patientSearchSelector = createSelector([patientTabsSelector], (tabs) => tabs.find((tab) => tab.id === 0 && tab.active))


// charges Selectors
export const chargesTabsSelector = createSelector([tabsSelector], (tabs) => tabs.charges2)
export const activeChargeSelector = createSelector([chargesTabsSelector], (tabs) => tabs.find((charge) => charge.active))
export const chargesSearchSelector = createSelector([chargesTabsSelector], (tabs) => tabs[0])
export const claimStatusSelector = createSelector([chargesSearchSelector], (tabs) => tabs.data.claimStatus)
export const patientIdSelector = createSelector([chargesSearchSelector], (tabs) => tabs.data.patientId)

// insurancePayment Selectors 
export const InsuranceTabSelector = createSelector([tabsSelector], (tabs) => tabs.insurancePayment)





