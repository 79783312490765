var CryptoJS = require('crypto-js')

const encryption = (txtpassword) => {
  var key = CryptoJS.enc.Utf8.parse('8080808080808080')
  var iv = CryptoJS.enc.Utf8.parse('8080808080808080')

  var encryptedpassword = CryptoJS.AES.encrypt(
    CryptoJS.enc.Utf8.parse(txtpassword),
    key,
    {
      keySize: 128 / 8,
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    },
  ).toString();
  return encryptedpassword;
}

export default encryption