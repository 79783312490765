import { useEffect } from "react";
import { FaUserAlt } from "react-icons/fa";
import { BiDotsVerticalRounded } from "react-icons/bi";
import { MdSpaceDashboard } from "react-icons/md";
import { IoMdNotifications } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { toggleSidebar } from "../../Redux/TabsSlice/TabsSlice";
import { Dropdown, Ripple, initTE } from "tw-elements";
import Refresh from "../../Components/Refresh/Refresh";
import Practices from "./Practices";
import { GiHamburgerMenu } from "react-icons/gi";
import { IoChevronBackSharp } from "react-icons/io5";
import { sidebarSelector } from "Redux/TabsSlice/TabsSelectors";

const Header = () => {
  const sidebar = useSelector(sidebarSelector)
  const dispatch = useDispatch()

  const onHandleLogout = () => {
    window.location.reload();

  }
  useEffect(() => {
    initTE({ Dropdown, Ripple });
  }, [])



  return (
    <header className='header relative h-[6vh] py-2 px-4  bg-primaryColor text-white flex items-center justify-between'>
      <div className="flex items-center w-full mr-auto space-x-4">
        <span className="text-lg cursor-pointer" onClick={() => dispatch(toggleSidebar(!sidebar))}>
          {sidebar ? <IoChevronBackSharp size={22} /> : < GiHamburgerMenu size={22} />}
        </span>{" "}
      </div>

      <div className="flex items-center space-x-4 text-lg">
        <Practices />
        <IoMdNotifications size={17} className="cursor-pointer" />
        <MdSpaceDashboard size={17} className="cursor-pointer" />
        <Refresh />
        <FaUserAlt size={17} className="cursor-pointer" />
        <div className="relative flex" data-te-dropdown-ref>
          <button className="" type="button" id="dsds" data-te-dropdown-toggle-ref aria-expanded="true"  >
            <BiDotsVerticalRounded size={17} />
          </button>
          <ul className="absolute z-[1000] float-left m-0 hidden min-w-max list-none overflow-hidden rounded-lg border-none bg-white bg-clip-padding text-left text-base shadow-lg dark:bg-neutral-700 [&[data-te-dropdown-show]]:block" aria-labelledby="dropdownMenuButton1" data-te-dropdown-menu-ref>
            <li>   <span onClick={onHandleLogout} className="block w-full px-4 py-2 text-sm font-normal bg-transparent cursor-pointer whitespace-nowrap text-neutral-700 hover:bg-neutral-100 active:text-neutral-800 active:no-underline disabled:pointer-events-none disabled:bg-transparent disabled:text-neutral-400 dark:text-neutral-200 dark:hover:bg-neutral-600" data-te-dropdown-item-ref>Logout</span> </li>
          </ul>
        </div>
      </div>
    </header>

  );
};

export default Header;