import React from 'react'
import CustomButton from '../../Common/CustomButton'
import { toast } from "react-toastify";
import { useNavigate } from 'react-router-dom';
import CutomPasswordInput from 'Common/CutomPasswordInput';

const UpdatePassword = () => {
    const navigate = useNavigate()
    const onHandleChange = (e) => {
        console.log("first")
    }
    const handleReset = async () => {
        toast.success("A reset link has been sent to your email please check your email!")
    }
    const handleEnter = (e) => {
        if (e.charCode === 13) {
            handleReset()
        }
    }
    const backToLogin = () => {
        navigate('/login');
    }

    let commonClass = 'w-[60px] h-[60px] absolute bg-white/20 pointer-events-none p-4 rounded-full'
    return (
        <div className=' from-primaryLightColorTwo to-secondaryColor/30 min-h-[100vh] flex justify-center items-center' onKeyPress={handleEnter}>
            <img src="/img/loginbg.jpg" className='fixed object-cover w-full h-full -z-10' alt="" />
            <div className='bg-white/20 relative p-4 min-w-[380px] flex flex-col justify-center items-center rounded-md shadow-md min-h-[400px]'>

                <img src='/img/logow.png' className='max-w-[200px] mb-auto' alt="logo is here" />
                <div className='flex flex-col w-full mb-auto space-y-3'>

                    <div className='col-span-12'>
                        <CutomPasswordInput label={"New Password"} type={'password'} onKeyPress={handleEnter} name="password" onChange={onHandleChange} labelClass="text-white required" />
                    </div>
                    <div className='col-span-12'>
                        <CutomPasswordInput label={"Confirm Password"} type={'password'} onKeyPress={handleEnter} name="password" onChange={onHandleChange} labelClass="text-white required" />
                    </div>
                    <CustomButton text={"Update"} onClick={handleReset}
                        extraClass="bg-white !text-primaryColor w-fit px-6 font-semibold border-[1px] transition-all hover:bg-white/80 " />
                    <span onClick={backToLogin} className='text-sm text-white cursor-pointer w-fit hover:underline'>Back to Login</span>
                </div>
                <span className={`${commonClass} -left-[54px] bottom-[60px] animate-box`}>
                    <img src="/img/icons/calendar.png" alt="calendar" />
                </span>
                <span className={`${commonClass} -left-[30px] -top-[33px] animate-box `}><img src="/img/icons/first-aid-kit.png" alt="first-aid-kit" /></span>
                <span className={`${commonClass} -top-[80px] -right-[25px] animate-box`}><img src="/img/icons/healthcare.png" alt="healthcare" /></span>
                <span className={`${commonClass}  -bottom-[20px] right-[40px] animate-box `}><img src="/img/icons/medical-history.png" alt="medical-history" /></span>
                <span className={`${commonClass}  top-[80px] -right-[80px] animate-box `}><img src="/img/icons/research.png" alt="research" /></span>
            </div>
            <span className='fixed font-bold text-white pointer-events-none text-7xl opacity-10 bottom-10 left-10'>AI POWERED MEDICARE SOLUTION</span>
        </div>
    )
}

export default UpdatePassword