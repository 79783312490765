import { lazy } from "react";
import { MdSpaceDashboard } from "react-icons/md";
const ReportingScreen = lazy(() => import(/* webpackChunkName: "ReportingScreen" */ "./ReportingScreen/ReportingScreen"));

const ChargesTotalByFacility = lazy(() => import("./ChargeTotalReports/ChargesTotalByFacility/ChargesTotalByFacility"))
const ChargesTotalByPayer = lazy(() => import("./ChargeTotalReports/ChargesTotalByPayer/ChargesTotalByPayer"))
const ChargesTotalByProvider = lazy(() => import("./ChargeTotalReports/ChargesTotalByProvider/ChargesTotalByProvider"))

const ChargesDueInsuranceByDateRange = lazy(() => import("./ChargesDueReports/ChargesDueInsuranceByDateRange/ChargesDueInsuranceByDateRange"))
const ChargesDuePatientByDateRange = lazy(() => import("./ChargesDueReports/ChargesDuePatientByDateRange/ChargesDuePatientByDateRange"))

const ClaimEnteredReport = lazy(() => import(/* webpackChunkName: "ClaimEnteredReport" */ "./ClaimReports/ClaimEnteredReport/ClaimEnteredReport"));

const ClaimWaitingForReview = lazy(() => import(/* webpackChunkName: "ClaimWaitingForReview" */ "./ClaimReports/ClaimWaitingForReview/ClaimWaitingForReview"));
const PatientClaimDetailReport = lazy(() => import(/* webpackChunkName: "PatientClaimDetailReport" */ "./ClaimReports/PatientClaimDetailReport/PatientClaimDetailReport"));
const CollectionReport = lazy(() => import(/* webpackChunkName: "CollectionReport" */ "./ClaimReports/CollectionReport/CollectionReport"));
const PatientDetailReport = lazy(() => import(/* webpackChunkName: "CollectionReport" */ "./ClaimReports/PatientDetailReport/PatientDetailReport"));

const PatientWiseAgingReport = lazy(() => import(/* webpackChunkName: "PatientWiseAgingReport" */ "./ClaimReports/PatientWiseAgingReport/PatientWiseAgingReport"));
const AnesthesiaUnitReport = lazy(() => import(/* webpackChunkName: "AnesthesiaUnitReport" */ "./ClaimReports/AnesthesiaUnitReport/AnesthesiaUnitReport"));
const PayerWiseSubmissionReport = lazy(() => import(/* webpackChunkName: "PayerWiseSubmissionReport" */ "./ClaimReports/PayerWiseSubmissionReport/PayerWiseSubmissionReport"));
const ProceduralCodeSummaryReport = lazy(() => import(/* webpackChunkName: "ProceduralCodeSummaryReport" */ "./ClaimReports/ProceduralCodeSummaryReport/ProceduralCodeSummaryReport"));


const DailyDepositReports = lazy(() => import(/* webpackChunkName: "DailyDepositReports" */ "./DailyReports/DailyDepositReports/DailyDepositReports"));
const DailyMonthlyChargesAndDebits = lazy(() => import(/* webpackChunkName: "DailyMonthlyChargesAndDebits" */ "./DailyReports/DailyMonthlyChargesAndDebits/DailyMonthlyChargesAndDebits"));

const FacilityChargesDetails = lazy(() => import(/* webpackChunkName: "FacilityChargesDetails" */ "./OtherReports/FacilityChargesDetails/FacilityChargesDetails"));
const InsuranceCheckListing = lazy(() => import(/* webpackChunkName: "InsuranceCheckListing" */ "./OtherReports/InsuranceCheckListing/InsuranceCheckListing"));
const PatientWithAccountCredits = lazy(() => import(/* webpackChunkName: "PatientWithAccountCredits" */ "./OtherReports/PatientWithAccountCredits/PatientWithAccountCredits"));
const PayerTypeBreakDown = lazy(() => import(/* webpackChunkName: "PayerTypeBreakDown" */ "./OtherReports/PayerTypeBreakDown/PayerTypeBreakDown"));

const TotalByDateReceived = lazy(() => import(/* webpackChunkName: "TotalByDateReceived" */ "./TotalReports/TotalByDateReceived/TotalByDateReceived"));
const TotalByFacility = lazy(() => import(/* webpackChunkName: "TotalByFacility" */ "./TotalReports/TotalByFacility/TotalByFacility"));
const TotalByProvider = lazy(() => import(/* webpackChunkName: "TotalByProvider" */ "./TotalReports/TotalByProvider/TotalByProvider"));




export const NewReportingTabsComponent = {
  ReportingScreen,
  DailyDepositReports,
  ClaimWaitingForReview,
  DailyMonthlyChargesAndDebits,
  ChargesDueInsuranceByDateRange,
  ChargesDuePatientByDateRange,
  ChargesTotalByFacility,
  ChargesTotalByPayer,
  ChargesTotalByProvider,
  ClaimEnteredReport,
  CollectionReport,
  PatientWiseAgingReport,
  AnesthesiaUnitReport,
  PayerWiseSubmissionReport,
  ProceduralCodeSummaryReport,

  FacilityChargesDetails,
  PatientWithAccountCredits,
  PayerTypeBreakDown,
  TotalByDateReceived,
  TotalByFacility,
  TotalByProvider,
  InsuranceCheckListing,
  PatientClaimDetailReport,
  PatientDetailReport

};

const NewReportingTabsObj = () => {
  return [
    {
      title: "Reporting",
      heading: "NewReporting",
      Component: "ReportingScreen",
      icon: MdSpaceDashboard,
      right: true,
    },
    // {
    //   title: "Daily Deposit Reports",
    //   heading: "Daily Reports",
    //   Component: "DailyDepositReports",
    //   icon: MdSpaceDashboard,
    //   right: true,
    // },
    // {
    //   title: "Daily Monthly Charges And Debits",
    //   heading: "Daily Reports",
    //   Component: "DailyMonthlyChargesAndDebits",
    //   icon: MdSpaceDashboard,
    //   right: true,
    // },
    // {
    //   title: "Claim Waiting For Review",
    //   heading: "Claim Reports",
    //   Component: "ClaimWaitingForReview",
    //   icon: MdSpaceDashboard,
    //   right: true,
    // },
    // {
    //   title: "Claim Entered Report",
    //   heading: "Claim Reports",
    //   Component: "ClaimEnteredReport",
    //   icon: MdSpaceDashboard,
    //   right: true,
    // },
    {
      title: "Collection report",
      heading: "Claim Reports",
      Component: "CollectionReport",
      icon: MdSpaceDashboard,
      right: true,
    },
    {
      title: "Anesthesia Unit Report",
      heading: "Claim Reports",
      Component: "AnesthesiaUnitReport",
      icon: MdSpaceDashboard,
      right: true,
    },
    {
      title: "Patient Claim Detail Report",
      heading: "Claim Reports",
      Component: "PatientClaimDetailReport",
      icon: MdSpaceDashboard,
      right: true,
    },
    {
      title: "Patient Detail Report",
      heading: "Claim Reports",
      Component: "PatientDetailReport",
      icon: MdSpaceDashboard,
      right: true,
    },
    {
      title: "Patient Wise Aging  Report",
      heading: "Claim Reports",
      Component: "PatientWiseAgingReport",
      icon: MdSpaceDashboard,
      right: true,
    },
    {
      title: "Payer Wise Submission Report",
      heading: "Claim Reports",
      Component: "PayerWiseSubmissionReport",
      icon: MdSpaceDashboard,
      right: true,
    },
    {
      title: "Procedural Code Summary Report",
      heading: "Claim Reports",
      Component: "ProceduralCodeSummaryReport",
      icon: MdSpaceDashboard,
      right: true,
    },
    // {
    //   title: "Charges Due Patient By Date Range",
    //   heading: "Charges Due Reports",
    //   Component: "ChargesDuePatientByDateRange",
    //   icon: MdSpaceDashboard,
    //   right: true,
    // },
    // {
    //   title: "Charges Due Insurance By Date Range",
    //   heading: "Charges Due Reports",
    //   Component: "ChargesDueInsuranceByDateRange",
    //   icon: MdSpaceDashboard,
    //   right: true,
    // },
    // {
    //   title: "Charges Due Insurance By Date Range",
    //   heading: "Charges Due Reports",
    //   Component: "ChargesDueInsuranceByDateRange",
    //   icon: MdSpaceDashboard,
    //   right: true,
    // },
    // {
    //   title: "Charges Total By Facility",
    //   heading: "Charges Total Reports",
    //   Component: "ChargesTotalByFacility",
    //   icon: MdSpaceDashboard,
    //   right: true,
    // },
    // {
    //   title: "Charges Total By Payer",
    //   heading: "Charges Total Reports",
    //   Component: "ChargesTotalByPayer",
    //   icon: MdSpaceDashboard,
    //   right: true,
    // },
    // {
    //   title: "Charges Total By Provider",
    //   heading: "Charges Total Reports",
    //   Component: "ChargesTotalByProvider",
    //   icon: MdSpaceDashboard,
    //   right: true,
    // },
    // {
    //   title: "Total By Date Received",
    //   heading: "Total Reports",
    //   Component: "TotalByDateReceived",
    //   icon: MdSpaceDashboard,
    //   right: true,
    // },
    // {
    //   title: "Total By Facility",
    //   heading: "Total Reports",
    //   Component: "TotalByFacility",
    //   icon: MdSpaceDashboard,
    //   right: true,
    // },
    // {
    //   title: "Total By Provider",
    //   heading: "Total Reports",
    //   Component: "TotalByProvider",
    //   icon: MdSpaceDashboard,
    //   right: true,
    // },


    // {
    //   title: "Facility Charges Details",
    //   heading: "Other Reports",
    //   Component: "FacilityChargesDetails",
    //   icon: MdSpaceDashboard,
    //   right: true,
    // },
    // {
    //   title: "Patient With Account Credits",
    //   heading: "Other Reports",
    //   Component: "PatientWithAccountCredits",
    //   icon: MdSpaceDashboard,
    //   right: true,
    // },
    // {
    //   title: "Payer Type Break Down",
    //   heading: "Other Reports",
    //   Component: "PayerTypeBreakDown",
    //   icon: MdSpaceDashboard,
    //   right: true,
    // },
    // {
    //   title: "Insurance Check Listing",
    //   heading: "Other Reports",
    //   Component: "InsuranceCheckListing",
    //   icon: MdSpaceDashboard,
    //   right: true,
    // },

  ]
}

export default NewReportingTabsObj