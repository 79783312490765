import React from 'react'
import { Outlet } from "react-router-dom";
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from './ErrorFallback';

function Layout() {
  const errorHandler = (someError, errorInfo) => {
    console.log("Logging>", someError, errorInfo);
  };
  return (
    <>
      <ErrorBoundary FallbackComponent={ErrorFallback} onError={errorHandler}   >
        <main className='main-wrapper'>
          <Outlet />
        </main>
      </ErrorBoundary>
    </>
  )
}

export default Layout