import { lazy } from "react";
import { FaHandHoldingMedical } from "react-icons/fa";
import { MdSpaceDashboard } from "react-icons/md";

// Admin Setup
const Location = lazy(() => import(/* webpackChunkName: "Location" */ "./AdminSetup/Location"));
const Provider = lazy(() => import(/* webpackChunkName: "Provider" */ "./AdminSetup/Provider"));
const Practice = lazy(() => import(/* webpackChunkName: "Practice" */ "./AdminSetup/Practice/Practice"));
const ReferringProvider = lazy(() => import(/* webpackChunkName: "ReferringProvider" */ "./AdminSetup/ReferringProvider"));
// Insurance / EDI setup
const Receiver = lazy(() => import(/* webpackChunkName: "InsurancePlanAddress" */ "./SubmissionSetup/Receiver"));
const Submitter = lazy(() => import(/* webpackChunkName: "InsurancePlanAddress" */ "./SubmissionSetup/Submitter"));
// Coding
const SetupScreen = lazy(() => import(/* webpackChunkName: "SetupScreen" */ "./SetupScreen/SetupScreen"));
const ICD = lazy(() => import(/* webpackChunkName: "ICD" */ "./Coding/ICD"));
const CPT = lazy(() => import(/* webpackChunkName: "CPT" */ "./Coding/CPT"));
const Modifiers = lazy(() => import(/* webpackChunkName: "Modifiers" */ "./Coding/Modifiers"));
const POS = lazy(() => import(/* webpackChunkName: "POS" */ "./Coding/POS"));
const AdjustmentCode = lazy(() => import(/* webpackChunkName: "Adjustment Code" */ "./Coding/AdjustmentCode"));
const ProviderFeeSchedule = lazy(() => import(/* webpackChunkName: "ProviderFeeSchedule" */ "./Coding/ProviderFeeSchedule"));
// Data Migration
const ExternalFiles = lazy(() => import(/* webpackChunkName: "ExternalFiles" */ "./DataMigration/ExternalFiles"));
// Submission Setup
const Insurance = lazy(() => import(/* webpackChunkName: "Insurance" */ "./InsuranceEdiCodes/Insurance"));
const InsurancePlanAddress = lazy(() => import(/* webpackChunkName: "InsurancePlanAddress" */ "./InsuranceEdiCodes/InsurancePlanAddress"));
const InsurancePlanEdiSetup = lazy(() => import(/* webpackChunkName: "InsurancePlanEdiSetup" */ "./InsuranceEdiCodes/InsurancePlanEdiSetup"));
const PaymentAdjustmentActionCodes = lazy(() => import(/* webpackChunkName: "PaymentAdjustmentActionCodes" */ "./InsuranceEdiCodes/PaymentAdjustmentActionCodes"));

// UserManagement Setup
const ViewUser = lazy(() => import(/* webpackChunkName: "ViewUser" */ "./UserManagement/ViewUser"));


export const SetupTabsComponent = {
  SetupScreen,
  Provider,
  Location,
  Practice,
  ReferringProvider,
  ICD,
  CPT,
  Modifiers,
  POS,
  AdjustmentCode,
  ProviderFeeSchedule,
  ExternalFiles,
  Insurance,
  InsurancePlanAddress,
  InsurancePlanEdiSetup,
  PaymentAdjustmentActionCodes,
  Receiver,
  Submitter,
  ViewUser
};

const SetupTabsObj = () => {
  return [
    {
      title: "Setup",
      heading: "setup",
      Component: "SetupScreen",
      icon: MdSpaceDashboard,
      right: true,
    },
    {
      title: "Practice",
      heading: "Admin Setup",
      Component: "Practice",
      icon: FaHandHoldingMedical,
      right: true,
    },
    {
      title: "Provider",
      heading: "Admin Setup",
      Component: "Provider",
      icon: MdSpaceDashboard,
      right: true,
    },
    {
      title: "Location",
      heading: "Admin Setup",
      Component: "Location",
      icon: FaHandHoldingMedical,
      right: true,
    },
    {
      title: "Referring Provider",
      heading: "Admin Setup",
      Component: "ReferringProvider",
      icon: FaHandHoldingMedical,
      right: true,
    },
    {
      title: "Location",
      heading: "Admin Setup",
      Component: "Location",
      icon: FaHandHoldingMedical,
      right: true,
    },


    {
      title: "Insurance",
      heading: "Insurance / EDI Codes",
      Component: "Insurance",
      icon: FaHandHoldingMedical,
      right: true,
    },
    {
      title: "Insurance Plan Address",
      heading: "Insurance / EDI Codes",
      Component: "InsurancePlanAddress",
      icon: FaHandHoldingMedical,
      right: true,
    },
    // changes
    {
      title: "Insurance Plan EDI Setup",
      heading: "Insurance / EDI Codes",
      Component: "InsurancePlanEdiSetup",
      icon: FaHandHoldingMedical,
      right: true,
    },
    {
      title: "Payment Adjustment Action Codes",
      heading: "Insurance / EDI Codes",
      Component: "PaymentAdjustmentActionCodes",
      icon: FaHandHoldingMedical,
      right: true,
    },
    {
      title: "ICD",
      heading: "Coding",
      Component: "ICD",
      icon: FaHandHoldingMedical,
      right: true,
    },
    {
      title: "CPT",
      heading: "Coding",
      Component: "CPT",
      icon: FaHandHoldingMedical,
      right: true,
    },
    {
      title: "Modifiers",
      heading: "Coding",
      Component: "Modifiers",
      icon: FaHandHoldingMedical,
      right: true,
    },
    {
      title: "POS",
      heading: "Coding",
      Component: "POS",
      icon: FaHandHoldingMedical,
      right: true,
    },
    {
      title: "AdjustmentCode",
      heading: "Coding",
      Component: "AdjustmentCode",
      icon: FaHandHoldingMedical,
      right: true,
    },

    {
      title: "Provider Fee Schedule",
      heading: "Coding",
      Component: "ProviderFeeSchedule",
      icon: FaHandHoldingMedical,
      right: true,
    },
    {
      title: "External Files",
      heading: "Data Migration",
      Component: "ExternalFiles",
      icon: FaHandHoldingMedical,
      right: true,
    },
    {
      title: "Receiver",
      heading: "Submission Setup",
      Component: "Receiver",
      icon: FaHandHoldingMedical,
      right: true,
    },
    {
      title: "Submitter",
      heading: "Submission Setup",
      Component: "Submitter",
      icon: FaHandHoldingMedical,
      right: true,
    },
    {
      title: "View User",
      heading: "User Management Setup",
      Component: "ViewUser",
      icon: FaHandHoldingMedical,
      right: true,
    },


  ];
};

export default SetupTabsObj;
