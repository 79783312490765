import React from 'react'
import Loading from "../../src/assets/gif/loading.gif"

const FallBackLoader = () => {
  return (
    <div className='items-center justify-center w-full  flex h-[90vh]'>
      <img src={Loading} alt="Loading..." />
    </div>
  )
}

export default FallBackLoader