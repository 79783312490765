import React from 'react';
const ErrorFallback = (props) => {
    return (
        <div className='flex items-center justify-center h-screen ' >
            <div className='text-center'>
                <h1 className='mb-8 text-5xl font-extrabold text-gray-800'>Whoops, Something Went Wrong</h1>
                <p className='mb-5 text-2xl font-medium '>Please either refresh the page or return home to try again</p>
                <p className='mb-5 text-2xl font-medium '>If the issue continues, pleasse <span className='text-red-400 underline cursor-pointer' >get in touch</span></p>
                <button onClick={() => { window.location.reload(window.location.href) }} className='px-5 py-4 text-xl text-white rounded-md shadow-lg bg-secondaryColor'>Go home</button>
            </div>
        </div>
    );
}

export default ErrorFallback;