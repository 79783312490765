import React, { useState } from 'react'
import { FiRefreshCcw } from "react-icons/fi";
import { DropDownApis } from '../../Common/Constants';
import { useDispatch } from 'react-redux';
import { dataSliceAction } from '../../Redux/DataSlice/DataSlice';
import api from '../../http';
import { ErrorHandlingMessage } from '../../Utils/ErrorHandlingMessage';
import FullPageLoader from '../FullPageLoader/FullPageLoader';
import { swtichTab } from '../../Redux/TabsSlice/TabsSlice';
const Refresh = () => {
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const handleRefresh = async () => {
        try {
            setLoading(true)
            const promises = DropDownApis.map(apiEndpoint => api.get(`${process.env.REACT_APP_TC_PMS}/api${apiEndpoint}`));
            const responses = await Promise.allSettled(promises);
            let keysAndData = {};
            responses.forEach(response => {
                if (response.status === 'fulfilled') {
                    let apiUrl = response.value.config.url;
                    let keyName = apiUrl.slice(apiUrl.lastIndexOf('/') + 1);
                    let data = response.value.data.dataModel;
                    keysAndData[keyName] = data
                }

            });

            const payload2 = { "param_list": [{ "key": "", "value": "" }], "practiceId": 0, "perPage": 0, "pageNo": 0, "totalPages": 0, "totalCount": 0, "isExportCall": true }
            const reciversResponse = await api.post(`${process.env.REACT_APP_TC_AUTH}/api/Receiver/FindReceiver`, payload2)
            const statusCodesResponse = await api.get(`${process.env.REACT_APP_TC_PMS}/api/Payment/FindERAClaimStatusCodes`)
            keysAndData["allRecivers"] = reciversResponse.data.dataModel.data
            keysAndData["ClaimStatusCodes"] = statusCodesResponse.data.dataModel
            dispatch(dataSliceAction(keysAndData))

            dispatch(swtichTab({ parentName: "dashboard" }))

        } catch (error) {
            ErrorHandlingMessage(error)
        }
        finally {
            setLoading(false)
        }
    }
    return (
        <div>
            {loading && <FullPageLoader />}
            <FiRefreshCcw size={17} onClick={handleRefresh} className="cursor-pointer" />
        </div>

    )
}

export default Refresh