import React from 'react'
import styles from "./SwitchPopup.module.css"
const SwitchPracticePopup = ({ onSwitch, onCancel, Title }) => {
    return (
        <div className={styles.darkBG}   >
            <div className={styles.centered}>
                <div className={styles.modal}>
                    <div className={styles.modalHeader}>
                        <h5 className={styles.heading}>Confirmation</h5>
                    </div>
                    <button className={styles.closeBtn} onClick={onCancel}>x</button>
                    <div className={styles.modalContent}>
                        {Title ? Title : "Are you sure you want to Switch?"}
                    </div>
                    <div className={styles.modalActions}>
                        <div className={styles.actionsContainer}>
                            <button className={styles.deleteBtn} onClick={onSwitch}>   Switch   </button>
                            <button className={styles.cancelBtn} onClick={onCancel}   >    Cancel   </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SwitchPracticePopup