import React, { useEffect, useState } from "react";
import api from "http";
import { ErrorHandlingMessage } from "Utils/ErrorHandlingMessage";
import SwitchPracticePopup from "Popups/SwitchPracticePopup/SwitchPracticePopup";
import { useDispatch, useSelector } from "react-redux";
import { signin, userAuthAction } from "Redux/UserAuthSlice/UserAuthSlice";
import { toast } from "react-toastify";
import { DropDownApis } from "Common/Constants";
import { dataSliceAction } from "Redux/DataSlice/DataSlice";
import FullPageLoader from "Components/FullPageLoader/FullPageLoader";
import CustomSelect from "Common/CustomSelect";
import { removePatient, swtichTab } from "Redux/TabsSlice/TabsSlice";

const Practices = () => {
  const [allPractices, setallPractices] = useState([]);
  const [popup, setpopup] = useState(false);
  const [practiceID, setpracticeID] = useState(0);
  const [selectedPracticeId, setselectedPracticeId] = useState("");
  const [loader, setloader] = useState(false);

  const dispatch = useDispatch();
  const { practiceDetails } = useSelector((state) => state.userAuth);

  const handleChange = (e) => {
    setselectedPracticeId(e.target.value);
    setpracticeID(e.target.value);
    setpopup(true);
  };

  const handleCancelPopup = () => {
    setpracticeID(0);
    setpopup(false);
    setselectedPracticeId(practiceDetails.id);
  };

  const handleSwitchPopup = async () => {
    try {
      setloader(true);
      const payload2 = {
        param_list: [{ key: "", value: "" }],
        practiceId: 0,
        perPage: 0,
        pageNo: 0,
        totalPages: 0,
        totalCount: 0,
        isExportCall: true,
      };
      const res = await api.get(
        `${process.env.REACT_APP_TC_AUTH}/api/Account/SwitchPractice/${practiceID}`
      );
      dispatch(signin(res.data.dataModel.token))
      const userInfo = await api.get(`${process.env.REACT_APP_TC_AUTH}/api/Account/GetUserInfo`)
      const obj = {
        userInfo: {
          ...userInfo.data.dataModel.loginUserInfo,
          token: res.data.dataModel.token
        },
        practiceDetails: userInfo.data.dataModel.practiceDetails
      }

      dispatch(userAuthAction(obj));
      dispatch(removePatient());

      const promises = DropDownApis.map(apiEndpoint => api.get(`${process.env.REACT_APP_TC_PMS}/api${apiEndpoint}`));
      const responses = await Promise.allSettled(promises);
      let keysAndData = {};
      responses.forEach(response => {
        if (response.status === 'fulfilled') {
          let apiUrl = response.value.config.url;
          let keyName = apiUrl.slice(apiUrl.lastIndexOf('/') + 1);
          let data = response.value.data.dataModel;
          keysAndData[keyName] = data
        }

      });

      const reciversResponse = await api.post(`${process.env.REACT_APP_TC_AUTH}/api/Receiver/FindReceiver`, payload2)
      const statusCodesResponse = await api.get(`${process.env.REACT_APP_TC_PMS}/api/Payment/FindERAClaimStatusCodes`)
      keysAndData["allRecivers"] = reciversResponse.data.dataModel.data
      keysAndData["ClaimStatusCodes"] = statusCodesResponse.data.dataModel
      dispatch(dataSliceAction(keysAndData));
      dispatch(swtichTab({ parentName: "dashboard" }))

      toast.success("Practice Switch Successfully");
    } catch (error) {
      ErrorHandlingMessage(error);
    } finally {
      setpracticeID(0);
      setpopup(false);
      setloader(false);
    }
  };
  useEffect(() => {
    (async () => {
      try {
        const response = await api.get(
          `${process.env.REACT_APP_TC_AUTH}/api/Account/FindUserAssignedPractices`
        );
        response.data.dataModel.shift(); // removing first index because we dont need that
        setallPractices(response.data.dataModel);
        setselectedPracticeId(practiceDetails.id);
      } catch (error) {
        ErrorHandlingMessage(error);
      }
    })();
  }, [practiceDetails.id]);
  return (
    <>
      {loader && <FullPageLoader />}
      {popup && (
        <SwitchPracticePopup
          Title={"Are you sure you want to Switch Practice"}
          onCancel={handleCancelPopup}
          onSwitch={handleSwitchPopup}
        />
      )}
      <CustomSelect value={selectedPracticeId} onChange={handleChange}>
        {allPractices.map((practice) => (
          <option
            key={practice.id}
            value={practice.id}
            className="p-2 text-black border-b cursor-pointer hover:bg-primaryColor hover:text-white"
          >
            {" "}
            {practice.label.split("-")[1]}{" "}
          </option>
        ))}
      </CustomSelect>{" "}
      <div
        className="relative flex"
        data-te-dropdown-ref
        data-te-dropdown-position="dropstart"
      ></div>
    </>
  );
};

export default Practices;