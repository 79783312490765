// import React from "react";
import React from "react";
import { MdSpaceDashboard } from "react-icons/md";
// import ChargesTabsObj from "../Billing/Charges/ChargesTabsObj";
// import PaymentTabsObj from "../Billing/Payments/PaymentTabsObj";
const ElectronicSubmission = React.lazy(() =>
  import(/* webpackChunkName: "ElectronicSubmission" */ "../Submission/ElectronicSubmission/ElectronicSubmission")
);
// const PaperSubmission = React.lazy(() =>
//   import(/* webpackChunkName: "PaperSubmission" */ "../Submission/PaperSubmission/PaperSubmission")
// );
const SubmissionLog = React.lazy(() =>
  import(/* webpackChunkName: "SubmissionLog" */ "../Submission/SubmissionLog/SubmissionLog")
);
const EDIResponseLog = React.lazy(() =>
  import(/* webpackChunkName: "EDIResponseLog" */ "../Submission/EDIResponseLog/EDIResponseLog")
);
// const PatientStatement = React.lazy(() =>
//   import(/* webpackChunkName: "PatientStatement" */ "../Submission/PatientStatement/PatientStatement")
// );
// const Payments = React.lazy(() =>
//   import(/* webpackChunkName: "Payments" */ "../Billing/Payments/Payments")
// );
// const FollowUp = React.lazy(() =>
//   import(/* webpackChunkName: "FollowUp" */ "../Billing/FollowUp/FollowUp")
// );
export const SubmissionTabsComponent = {
  ElectronicSubmission,
  // PaperSubmission,
  SubmissionLog,
  EDIResponseLog,
  // PatientStatement,
}
const SubmissionTabsObj = () => {
  return [
    {
      title: "Submission",
      name: "submission",
      Component: "ElectronicSubmission",
      icon: MdSpaceDashboard,
      right: true,
      eventKey: 88,
      key: 8,
      data: { activeTab: "unbilled", visitValidation: "all" }
    },
    {
      title: "Submission Log",
      name: "submissionLog",
      Component: "SubmissionLog",
      icon: MdSpaceDashboard,
      right: true,
      eventKey: 68,
      key: 9,
      data: {}
    },
    {
      title: "EDI Response Log",
      name: "ediResponseLog",
      Component: "EDIResponseLog",
      icon: MdSpaceDashboard,
      right: true,
      eventKey: 68,
      key: 10,
      data: {}
    },
    // {
    //   title: "Patient Statement",
    //   name: "patientStatement",
    //   Component: "PatientStatement",
    //   icon: MdSpaceDashboard,
    //   right: true,
    //   eventKey: 68,
    //   key: 11,
    //   data: {}
    // },

  ];
};

export default SubmissionTabsObj;
