import React, { useEffect, useState, Suspense } from "react";
import Sidebar from "./../../Common/Sidebar";
import { TabContent, TabPane } from "reactstrap";
// import { addToMainTab } from '../../Redux/TabsSlice/TabsSlice';
import { useDispatch, useSelector } from "react-redux";
import { MainTabsComponent } from "./MainTabsObj";
import {
  activeMainTab,
  setInsurancePayment,
} from "../../Redux/TabsSlice/TabsSlice";
import GlobalNavigation from "../../Common/GlobalNavigationObj";
import Header from "../../Common/Header/Header";
import FallBackLoader from "Common/FallBackLoader";
import { motion } from "framer-motion";
import { sidebarSelector } from "Redux/TabsSlice/TabsSelectors";

const MainTabItems = GlobalNavigation;
const currentTab = 0;
const HomePage = () => {
  const { mainTab } = useSelector((state) => state.tabs);
  const sidebar = useSelector(sidebarSelector);
  const [sidebarToggle, setSidebarToggle] = useState(true);
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState(null);
  const [tabsmodal, settabsmodal] = useState({});
  const { token } = useSelector((state) => state.userAuth.userinfo);

  const handleMenuItemClick = (key, name, right, title, data) => {
    if (name === "insurance payment") {
      dispatch(
        setInsurancePayment({
          mainTab: { key, name, right, title },
          data: data,
        })
      );
    } else {
      dispatch(activeMainTab({ key, name, right, title, data }));
    }

    setActiveTab(key);
    settabsmodal({ ...tabsmodal, [name]: true });
  };
  useEffect(() => {
    setActiveTab(null);
    settabsmodal({});
  }, [token]);
  useEffect(() => {
    if (!mainTab) {
      let finalTabsModal = MainTabItems.map((item, index) => ({
        title: item.title,
        name: item.title.toLowerCase(),
        right: item.right,
        key: item.key,
      }));
      let tabsModal = {};
      finalTabsModal.forEach(
        (item, index) =>
          (tabsModal[item.name] =
            item.key === MainTabItems[currentTab].key ? true : false)
      );

      settabsmodal(tabsModal);
      setActiveTab(finalTabsModal[currentTab].key);
      dispatch(activeMainTab(finalTabsModal[currentTab]));
    } else if (activeTab !== mainTab.key) {
      setActiveTab(mainTab.key);
      settabsmodal({ ...tabsmodal, [mainTab.name]: true });
    }
  }, [mainTab, tabsmodal, activeTab, dispatch]);
  const show = {
    transition: { ease: "easeOut", duration: 0.5 },
    opacity: 1,
  };

  const hide = {
    opacity: 0,
  };
  return (
    <>
      <div id="left-sidebar" style={{ width: sidebar ? 250 : 50 }}>
        <Sidebar
          mainTabs={MainTabItems}
          activeTab={activeTab}
          onItemClick={handleMenuItemClick}
        />
      </div>
      <div
        id="main-content"
        style={{
          width: `${sidebar ? "calc(100% - 250px)" : "calc(100% - 50px)"}`,
        }}
      >
        <div className="navbar-fixed-top">
          <Header
            sidebarToggle={sidebarToggle}
            setSidebarToggle={setSidebarToggle}
          />
        </div>
        {
          <TabContent activeTab={activeTab}>
            <Suspense fallback={<FallBackLoader />}>
              {MainTabItems.filter(
                (v) => tabsmodal[v.title.toLowerCase()] && v.Component
              ).map((Item) => {
                const Component = MainTabsComponent[Item.Component];

                return (
                  <TabPane id={Item.key} tabId={Item.key}>
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={activeTab === Item.key ? show : hide}
                    >
                      <Component
                        parentKey={Item.key}
                        subTabs={Item.subTabs}
                        parentName={Item.name}
                      />
                    </motion.div>
                  </TabPane>
                );
              })}
            </Suspense>
          </TabContent>
        }
      </div>
    </>
  );
};

export default HomePage;
