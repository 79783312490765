import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import CustomButton from '../../Common/CustomButton'
import CustomInput from '../../Common/CustomInput'
import CutomPasswordInput from '../../Common/CutomPasswordInput'
import encryption from '../../Utils/encryptionPassword'
import api from '../../http'
import { useDispatch, } from 'react-redux'
import { signin, userAuthAction } from '../../Redux/UserAuthSlice/UserAuthSlice'
import { dataSliceAction } from '../../Redux/DataSlice/DataSlice'
import { DropDownApis } from '../../Common/Constants'
import { ErrorHandlingMessage } from '../../Utils/ErrorHandlingMessage'
import { toast } from 'react-toastify'

const LoginPage = () => {

    const dispatch = useDispatch()
    const [loginObj, setLoginObj] = useState({
        email: "",
        password: "",
    })

    const [loading, setLoading] = useState(false)
    const [error, setError] = useState("")

    const forgotPassword = () => {
        navigate('/forgot-password');
    };

    const onHandleChange = (e) => {
        setLoginObj((prevobj) => ({
            ...prevobj,
            [e.target.name]: e.target.value
        }))
    }

    const navigate = useNavigate()
    const handleLogin = async () => {
        const allowedEmails = ['m.danish@gettrucare.com', 'arslan.ahmad@gettrucare.com', 'shehbaz.khan@gettrucare.com', 'saqlain.mushtaq@gettrucare.com']
        const { email, password } = loginObj
        if (!allowedEmails.includes(email)) {

            toast.warn('Unauthorized Email !')
            return
        }
        let encryptPassword = encryption(password)
        const payload = {
            email,
            password: encryptPassword
        }
        try {
            setLoading(true)
            const res = await api.post(`${process.env.REACT_APP_TC_AUTH}/api/Account/Login`, payload)
            dispatch(signin(res.data.dataModel.token))
            const userInfo = await api.get(`${process.env.REACT_APP_TC_AUTH}/api/Account/GetUserInfo`)
            const obj = {
                userInfo: {
                    ...userInfo.data.dataModel.loginUserInfo,
                    token: res.data.dataModel.token
                },
                practiceDetails: userInfo.data.dataModel.practiceDetails
            }

            dispatch(userAuthAction(obj));
            const promises = DropDownApis.map(apiEndpoint => api.get(`${process.env.REACT_APP_TC_PMS}/api${apiEndpoint}`));
            const responses = await Promise.allSettled(promises);
            let keysAndData = {};
            responses.forEach(response => {
                if (response.status === 'fulfilled') {
                    let apiUrl = response.value.config.url;
                    let keyName = apiUrl.slice(apiUrl.lastIndexOf('/') + 1);
                    let data = response.value.data.dataModel;
                    keysAndData[keyName] = data
                }

            });

            const payload2 = { "param_list": [{ "key": "", "value": "" }], "practiceId": 0, "perPage": 0, "pageNo": 0, "totalPages": 0, "totalCount": 0, "isExportCall": true }
            const reciversResponse = await api.post(`${process.env.REACT_APP_TC_AUTH}/api/Receiver/FindReceiver`, payload2)
            const statusCodesResponse = await api.get(`${process.env.REACT_APP_TC_PMS}/api/Payment/FindERAClaimStatusCodes`)
            const typesOfServices = await api.get(`${process.env.REACT_APP_TC_COMMON}/api/TypeOfService/FindTypeOfServices`)
            console.log("this is the output", typesOfServices.data.dataModel)
            keysAndData["allRecivers"] = reciversResponse.data.dataModel.data
            keysAndData["ClaimStatusCodes"] = statusCodesResponse.data.dataModel
            keysAndData["TypesOfServices"] = typesOfServices.data.dataModel
            dispatch(dataSliceAction(keysAndData));
            navigate("/");
        } catch (error) {
            setLoading(false)
            setError(error.response.data.responseMessage)
            ErrorHandlingMessage(error)
        }
    }
    const handleEnter = (e) => {
        if (e.charCode === 13) {
            handleLogin()
        }
    }


    let commonClass = 'w-[60px] h-[60px] absolute bg-white/20 pointer-events-none p-4 rounded-full'

    return (

        <div className=' from-primaryLightColorTwo to-secondaryColor/30 min-h-[100vh] flex justify-center items-center'  >

            <img src="/img/loginbg.jpg" className='fixed object-cover w-full h-full -z-10' alt="" />
            <div className='bg-white/20 relative p-4 min-w-[380px] flex flex-col justify-center items-center rounded-md shadow-md min-h-[400px]'>

                <img src='/img/logow.png' className='max-w-[200px] mb-auto' alt="logo is here" />
                <div className='flex flex-col w-full mb-auto space-y-3'>
                    <CustomInput label={"Email"} type="email" autoFocus name="email" onKeyPress={handleEnter} value={loginObj.email} onChange={onHandleChange} labelClass="text-white required" />
                    <div className='grid grid-cols-12 gap-1 text-white'>
                        <div className='col-span-12'>
                            <CutomPasswordInput label={"Password"} type={'password'} onKeyPress={handleEnter} name="password" onChange={onHandleChange} value={loginObj.password} labelClass="text-white required" />
                        </div>
                        {/* <div className='flex items-end justify-center col-span-1 pb-1'>
                            {showPassword ? < AiOutlineEyeInvisible onClick={handlePassword} size={26} className='cursor-pointer' /> : <AiOutlineEye size={26} onClick={handlePassword} className='cursor-pointer' />}
                        </div> */}
                    </div>
                    <CustomButton text={"Login"} loading={loading} onClick={handleLogin}
                        // onClick={()=>navigate("/TruePortal")}
                        extraClass="bg-white !text-primaryColor w-fit px-6 font-semibold border-[1px] transition-all hover:bg-white/80 " />
                    <span onClick={forgotPassword} className='text-sm text-white cursor-pointer w-fit hover:underline'>Forgot password ?</span>
                    {error ? <p className='p-2 font-semibold bg-green-200 rounded-sm text-white-900'>{error}</p> : ""}
                </div>
                <span className={`${commonClass} -left-[54px] bottom-[60px] animate-box`}>
                    <img src="/img/icons/calendar.png" alt="" />
                </span>
                <span className={`${commonClass} -left-[30px] -top-[33px] animate-box `}><img src="/img/icons/first-aid-kit.png" alt="" /></span>
                <span className={`${commonClass} -top-[80px] -right-[25px] animate-box`}><img src="/img/icons/healthcare.png" alt="" /></span>
                <span className={`${commonClass}  -bottom-[20px] right-[40px] animate-box `}><img src="/img/icons/medical-history.png" alt="" /></span>
                <span className={`${commonClass}  top-[80px] -right-[80px] animate-box `}><img src="/img/icons/research.png" alt="" /></span>
            </div>
            <span className='fixed font-bold text-white pointer-events-none text-7xl opacity-10 bottom-10 left-10'>AI POWERED MEDICARE SOLUTION</span>
        </div>
    )
}

export default LoginPage