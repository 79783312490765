import { createSlice } from "@reduxjs/toolkit";

const initial_State = {
  FindBillingProviders: [],
  FindSupervisingProviders: [],
  FindOrderingProviders: [],
  FindAttendingProviders: [],
  FindModifiers: [],
  FindVisitStatusCodes: [],
  FindPos: [],
  FindRefProviders: [],
  FindLocations: [],
  FindTaxonomy: [],
  allRecivers: [],
  ClaimStatusCodes: [],
  TypesOfServices:[]

};

export const DataSlice = createSlice({
  name: "DataSlice",
  initialState: initial_State,
  reducers: {
    dataSliceAction: (state, action) => {
      return {
        ...state,
        ...action.payload
      }
    },
  },
});

// Action creators are generated for each case reducer function
export const { dataSliceAction } = DataSlice.actions;

export default DataSlice.reducer;