import React, { useState } from "react";
import { AiOutlineEyeInvisible } from "react-icons/ai";
import handleNumericCheck from "../Utils/NumericCheck";


const CutomPasswordInput = ({ label, required, labelClass, maxFloat, isNumeric, isFloat, extraClass, type, ...inputProps }) => {
  const [showPassword, setShowPassword] = useState(false)
  return (
    <div className="relative flex flex-col w-full">
      {label && (
        <label className={`${labelClass ? labelClass : "text-gray-700"} ${required && " required"}`}>
          {label}
        </label>
      )}
      <input
        type={showPassword ? "text" : type}
        onKeyPress={isNumeric ? (e) => handleNumericCheck(e, isFloat ? "allowFloat" : "", maxFloat) : () => { }}
        onInput={(e) => e.target.value = ("" + e.target.value)}
        {...inputProps}
        className={` p-[0.4rem]  focus:shadow-md !shadow-primaryColor/10 focus:border-primaryColor border border-gray-300 text-base focus:outline-none focus:ring-0  rounded text-gray-900  disabled:bg-gray-300 ${extraClass}`}
      />
      {type === "password" && < AiOutlineEyeInvisible className={`absolute text-primaryColor ${!showPassword ? "opacity-50" : "opacity-100"} right-2 top-1/2 cursor-pointer`} onClick={() => setShowPassword(!showPassword)} size={23} />}
    </div>
  )
}

export default CutomPasswordInput