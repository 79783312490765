

import MainTabsObj from "../Pages/HomePage/MainTabsObj"
let keyCounter = 0
export const KeyGen = (items)=>{
 
    return items.map((item,mainIndex)=>{
        keyCounter +=1
        if(item.subTabs){
           return ({...item,name:item.title?.toLowerCase().trim(),key:keyCounter,subTabs:KeyGen(item.subTabs)})
        }else{
           return ({...item,name:item.title?.toLowerCase().trim(),key:keyCounter})
        }
    })
}




// let billingItems =   KeyGen(BillingTabsObj())
// let setupItems = KeyGen(Setup())
const mainItems = KeyGen(MainTabsObj())
const GlobalNavigation = mainItems
export default GlobalNavigation