import { FaWpforms } from "react-icons/fa";
import { MdOutlinePayments } from "react-icons/md";

import { lazy } from "react";

import SetupTabsObj from "../../MainTabs/Setup/SetupTabsObj";
import ChargesTabsObj from "../../MainTabs/Charges/ChargesTabsObj";
import NewReportingTabsObj from "../../MainTabs/Reporting/NewReportingTabsObj";
import SubmissionTabsObj from "../../MainTabs/Submission/SubmissionTabsObj";
import { RxDashboard } from "react-icons/rx";
import { TbReportAnalytics, TbReportMedical } from "react-icons/tb";
import { CiMedicalCross } from "react-icons/ci";
import { BiCog, BiDetail } from "react-icons/bi";
import { BsCardChecklist } from "react-icons/bs";

const Dashboard = lazy(() => import(  /* webpackChunkName: "Dashboard" */ "../../MainTabs/Dashboard/Dashboard"));
const Patient = lazy(() => import(/* webpackChunkName: "Patient" */ "../../MainTabs/Patient/Patient"));
const Setup = lazy(() => import(/* webpackChunkName: "Setup" */ "../../MainTabs/Setup/Setup"));
const Submission = lazy(() => import(/* webpackChunkName: "Submission" */ "../../MainTabs/Submission/Submission"));
const Reporting = lazy(() => import(/* webpackChunkName: "Reporting" */ "../../MainTabs/Reporting/Reporting"));
const Charges = lazy(() => import(/* webpackChunkName: "Charges" */ "../../MainTabs/Charges/Charges"));
const InsurancePayment = lazy(() => import(/* webpackChunkName: "InsurancePayment" */ "../../MainTabs/InsurancePayment/InsurancePayment"));
const PatientPayment = lazy(() => import(/* webpackChunkName: "PatientPayment" */ "../../MainTabs/PatientPayment/PatientPayment"));
const PatientStatement = lazy(() => import(/* webpackChunkName: "PatientStatement" */ "../../MainTabs/PatientStatement/PatientStatement"));
const Charges2 = lazy(() => import(/* webpackChunkName: "Patient" */ "../../MainTabs/Charges2/Charges2"));

export const MainTabsComponent = {
  Dashboard,
  Patient,
  Setup,
  Submission,
  Reporting,
  Charges,
  Charges2,
  InsurancePayment,
  PatientPayment,
  PatientStatement,
}
const MainTabsObj = () => {
  return [
    {
      title: "Dashboard",
      Component: "Dashboard",

      icon: RxDashboard,
      right: true,
      eventKey: 68, // press d from keyboard
      data: {}
    },
    {
      title: "Patient",
      Component: "Patient",
      icon: CiMedicalCross,
      right: true,
      eventKey: 68, // press d from keyboard
      data: {}
    },
    // {
    //   title: "Billing",
    //   Component: "Billing",
    //   icon: FaFileMedical,
    //   right: true,
    //   eventKey: 68,
    //   subTabs: BillingTabsObj(), // press d from keyboard
    // },
    {
      title: "Charges",
      Component: "Charges",
      icon: TbReportMedical,
      right: true,
      subTabs: ChargesTabsObj(),
      eventKey: 68, // press d from keyboard
      data: {}
    },

    {
      title: "Submission",
      Component: "Submission",
      subTabs: SubmissionTabsObj(),
      icon: FaWpforms,
      right: true,
      eventKey: 68, // press d from keyboard
      data: {}
    },

    {
      title: "Insurance Payment",
      Component: "InsurancePayment",
      icon: MdOutlinePayments,
      right: true,
      eventKey: 68, // press d from keyboard
      data: {
        pID: 0,
        InsData: {
          paymenttype: "ERA_EOB",
          payerid: "",
          payerName: "",
          checknumber: "",
          checkdatefrom: "",
          checkdateto: "",
          depositdatefrom: "",
          depositdateto: "",
          poststatus: "NeedPosting",
          entrydatefrom: "",
          entrydateto: "",
          receiveddatefrom: "",
          receiveddateto: "",
          visitid: "",
          posteddatefrom: "",
          posteddateto: "",
          dateType: "Check Date",
        },
        rowData: null
      }
    },
    {
      title: "Patient Payment",
      Component: "PatientPayment",
      icon: BsCardChecklist,
      right: true,
      eventKey: 68, // press d from keyboard
      data: {}
    },
    {
      title: "Patient Statement",
      name: "patientStatement",
      Component: "PatientStatement",
      icon: BiDetail,
      right: true,
      eventKey: 68,
      key: 11,
      data: {}
    },
    {
      title: "Reporting",
      Component: "Reporting",
      icon: TbReportAnalytics,
      right: true,
      eventKey: 68,
      subTabs: NewReportingTabsObj(),
      data: {}
    },
    // {
    //   title: "New Reporting",
    //   Component: "NewReporting",
    //   icon: TbReportAnalytics,
    //   right: true,
    //   eventKey: 68,
    //   subTabs: NewReportingTabsObj(),
    // },
    // {
    //   title: "Scheduler",
    //   icon: MdAvTimer,
    //   right: true,
    //   eventKey: 68, // press d from keyboard
    // },

    {
      title: "Setup",
      Component: "Setup",
      icon: BiCog,
      right: true,
      subTabs: SetupTabsObj(),
      eventKey: 68, // press d from keyboard
      data: {}
    },
    // {
    //   title: "Charges2",
    //   Component: "Charges2",
    //   icon: CiMedicalCross,
    //   right: true,
    //   eventKey: 68, // press d from keyboard 
    //   data: {}
    // },

  ];
};

export default MainTabsObj;
