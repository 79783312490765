// import axios from 'axios';
// import { store } from '../store'
// export const api = (token) => {
//     let accessToken = store.getState().userAuth.userinfo.accessToken
//     const headers = {
//         'Content-type': 'application/json',
//         Accept: 'application/json',
//     };

//     if (accessToken) {
//         headers.Authorization = `Bearer ${accessToken}`;
//     }

//     return axios.create({
//         baseURL: "http://localhost:5000",
//         withCredentials: true,
//         headers,
//     });
// };

// export default api;
// // interceptors
// api.interceptors.response.use(
//     (config) => {
//         return config;
//     },
//     async (error) => {
//         const originalRequest = error.config;
//         if (
//             error.response.status === 401 &&
//             originalRequest &&
//             !originalRequest.isRetry
//         ) {
//             originalRequest.isRetry = true;
//             try {
//                 const { accessToken, refreshToken } = store.getState().userAuth.userinfo
//                 if (accessToken) {
//                     originalRequest.headers.Authorization = `Bearer ${accessToken}`;
//                 }

//                 await axios.get(
//                     `http://localhost:5000/api/refresh`,
//                     {
//                         withCredentials: true,

//                         headers: {
//                             Authorization: `Bearer ${refreshToken}`,
//                         },

//                     }
//                 );

//                 return api().request(originalRequest);
//             } catch (err) {
//                
//             }
//         }
//         throw error;
//     }
// );






/// METHOD 3

import axios from 'axios';
import { store } from '../store'
// import { refresh } from "../Redux/UserAuthSlice/UserAuthSlice"

const api = axios.create({
    withCredentials: false,
    headers: {
        Accept: "*/*",
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "Access-Control-Max-Age": "6000",
        "Access-Control-Allow-Headers": "*",
    },
});

// Add an interceptor to include the token in the headers of the request
api.interceptors.request.use(
    (config) => {
        let token = store.getState().userAuth.userinfo.token
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// Add an interceptor to refresh the token if it has expired
api.interceptors.response.use(
    (response) => {
        return response;
    },
    async (error) => {
        const originalRequest = error.config;
        if (error.response.status === 401 && originalRequest && !originalRequest.isRetry) {
            originalRequest.isRetry = true;
            try {
                // let refreshToken = store.getState().userAuth.userinfo.refreshToken
                const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/refresh`,);
                const newTokens = response.data;
                // store.dispatch(refresh({ newTokens }));
                originalRequest.headers.Authorization = `Bearer ${newTokens.accessToken}`;
                return api(originalRequest);
            } catch (err) {
                console.log(err.message);
            }
        }
        return Promise.reject(error);
    }
);

export default api;
















// import axios from 'axios';
// //: "https://busy-pear-mackerel-yoke.cyclic.app/",
// export const api =

//     axios.create({
//         baseURL: "http://localhost:5000",
//         withCredentials: true,
//         headers: {
//             'Content-type': 'application/json',
//             Accept: 'application/json',
//         },
//     })




// // interceptors
// api.interceptors.response.use(
//     (config) => {
//         return config;
//     },
//     async (error) => {
//         const originalRequest = error.config;
//         if (
//             error.response.status === 401 &&
//             originalRequest &&
//             !originalRequest.isRetry
//         ) {
//             originalRequest.isRetry = true;
//             try {
//                 await axios.get(
//                     `http://localhost:5000/api/refresh`,
//                     {
//                         withCredentials: true,
//                     }
//                 );

//                 return api.request(originalRequest);
//             } catch (err) {
//
//             }
//         }
//         throw error;
//     }
// );

// export default api;