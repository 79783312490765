import { sidebarSelector } from "Redux/TabsSlice/TabsSelectors";
import React from "react";
import { useSelector } from "react-redux";
const Sidebar = ({ mainTabs, activeTab, onItemClick }) => {
  const sidebar = useSelector(sidebarSelector)
  return (
    // -left-[145px] left-0
    <nav className={`flex flex-col border-r   items-center h-full  bg-white  transition-all  ease-in-out fixed md:static md:block  ${sidebar ? "md:-left-[210px] block left-0" : "left-0  md:left-0"}`}>
      <div className="mb-6 border-b ">
        <span className="flex items-center transition-[max-width]  ease-in-out duration-700 justify-center p-3 py-[0.6rem] "><img src={`${sidebar ? "/img/logo.png" : "/img/favicon.png"}`} alt="logo" className={`${sidebar ? "max-w-[170px]" : "max-w-[30px]"}`} /></span>
      </div>
      <ul className={`self-start  text-base space-y-3 transition-all duration-300 ease-in-out ${sidebar ? " left-0 w-[210px] " : " w-[46px] overflow-hidden left:0 "} z-10   md:bg-white   `}   >
        {mainTabs.map(({ key, title, name, right, icon, data }) => (
          <SidebarItem
            text={title}
            sidebar={sidebar}
            Icon={icon}
            key={key}
            selectedTab={key}
            activeTab={activeTab}
            name={name}
            right={right}
            onItemClick={onItemClick}
            data={data}
          />
        ))}

      </ul>
    </nav>
  );
};
export default Sidebar;


const SidebarItem = ({ selectedTab, text, Icon, name, sidebar, right, activeTab, data, onItemClick }) => {
  return (
    <li onClick={() => onItemClick(selectedTab, name, right, text, data)} className={`flex  ${activeTab === selectedTab && "text-primaryColor font-semibold  "} transition-all  min-w-[200px]  ease-in-out duration-400 cursor-pointer  ${!sidebar ? "px-4" : "px-5"} py-3  hover:text-primaryColor hover:font-bold items-center w-full ${!sidebar ? "mx-auto  " : ""} `}>
      <Icon className={`mr-1 transition-[font-size] min-w-[20px] ease-in-out text-2xl  ${!sidebar ? "text-2xl" : ""}`} />
      {<span className="block ml-4 min-w-[160px]  text-base md:inline-block">{text}</span>}
    </li>
  );
};
