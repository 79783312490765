import moment from "moment-mini";

export const relationship = [
  { value: "", display: "Select Relationship" },
  { value: "18", display: "18 SELF" },
  { value: "01", display: "01 SPOUSE" },
  { value: "19", display: "19 CHILD" },
  { value: "20", display: "20 EMPLOYEE" },
  { value: "21", display: "21 UNKNOWN" },
  { value: "39", display: "39 ORGAN DONAR" },
  { value: "53", display: "53 LIFE PARTNER" },
  { value: "G8", display: "G8 OTHER RELATIONSHIP" },
];


export const pattientSearchDropDown = [
  { text: "First name", value: "Firstname" },
  { text: "Last name", value: "Lastname" },
  { text: "Account no", value: "Accountnum" },
  { text: "Dob", value: "Dob" },
  { text: "SSN", value: "Ssn" },
  { text: "Gender", value: "Gender" },
]

export const providerSearchDropDown = [
  { text: "Is Not Participating", value: "ISNOTPARTICIPATING" },
  { text: "Cpt Code", value: "CPTCODE" },
  { text: "Provider Id", value: "PROVIDERID" },
  { text: "Location Id", value: "LOCATIONID" },
  { text: "Modifier Code", value: "MODIFIERCODE" },
  { text: "Insurance Plan Id", value: "INSURANCEPLANID" },
]

export const ICDSearchDropDown = [
  { text: "ICD Code", value: "ICDCODE" },
  { text: "Description", value: "DESCRIPTION" },
  { text: "Short Description", value: "SHORTDESCRIPTION" },
]
export const POSSearchDropDown = [
  { text: "POS Code", value: "POSCODE" },
  { text: "Description", value: "DESCRIPTION" },
  // { text: "Short Description", value: "SHORTDESCRIPTION" },
]
export const AdjustmentCodeSearchDropDown = [
  { text: "Adjustment Code", value: "ADJUSTMENTCODE" },
  { text: "Description", value: "DESCRIPTION" },
  // { text: "Short Description", value: "SHORTDESCRIPTION" },
]

export const InsurancePlanDropdown = [

  { text: "Payer Name", value: "PAYERNAME" },
  { text: "Description", value: "DESCRIPTION" },
]

export const InsuranceDropdown = [

  { text: "Plan Name", value: "PAYERNAME" },
  { text: "Description", value: "DESCRIPTION" },
  { text: "Plan Name_Description", value: "PAYERNAME_DESCRIPTION" },
]


export const InsurancePlanAddressDropdown = [

  { text: "Payer Name", value: "PAYERNAME" },
  { text: "Address", value: "ADDRESS" },
  { text: "Phone Number", value: "PHONENUMBER" },
]

export const PaymentAdjustmentActionCodesDropdown = [

  { text: "Description", value: "DESCRIPTION" },
  { text: "Rejection Code", value: "REJECTIONCODE" },
  { text: "Group Code ", value: "GROUPCODE" },
  { text: "Reason Code", value: "REASONCODE" },
  { text: "Remark Code", value: "REMARKCODE" },
  { text: "Status", value: "STATUS" },
]

export const InsurancePlanEdiSetupDropdown = [
  { text: "Plan name", value: "INSURANCEPLANNAME" },
  { text: "Reciver Id", value: "RECEIVERID" },
  { text: "Insurance Plan Id", value: "INSURANCEPLANID" },
  { text: "Default Player Type", value: "DEFAULTPAYERTYPE" },
]


export const CPTSearchDropDown = [
  { text: "CPT Code", value: "CPTCODE" },
  { text: "Description", value: "DESCRIPTION" },
]

export const ModifierSearchDropDown = [
  { text: "Code", value: "CODE" },
  { text: "Description", value: "DESCRIPTION" },
]
export const InsuranceSearchDropDown = [
  { text: "CPT Code", value: "CptCode" },
  { text: "Description", value: "DESCRIPTION" },
]
export const ReceiverSearchDropDown = [
  { text: "Receiver Name", value: "NAME" },
  { text: "Receiver Address", value: "ADDRESS" },
]
export const SubmitterSearchDropDown = [
  { text: "Submitter Name", value: "NAME" },
]

export const titles = [
  { value: "", display: "Select" },
  { value: "MR.", display: "MR" },
  { value: "MRS.", display: "MRS" },
];
export const DropDownApis = [
  "/DropDown/FindBillingProviders",
  "/DropDown/FindSupervisingProviders",
  "/DropDown/FindOrderingProviders",
  "/DropDown/FindAttendingProviders",
  "/DropDown/FindModifiers",
  "/DropDown/FindVisitStatusCodes",
  "/DropDown/FindPos",
  "/DropDown/FindRefProviders",
  "/DropDown/FindLocations",
  // "/DropDown/FindCpts",
  "/DropDown/FindTaxonomy",
  "/DropDown/FindVisitStatusCodes",
]

export const ClaimStatuses = [
  {
    "id": null,
    "value": "",
    "label": "All"
  },
  {
    "id": null,
    "value": "N",
    "label": "New Charge"
  },
  {
    "id": null,
    "value": "B",
    "label": "Billed"
  },
  {
    "id": null,
    "value": "NR",
    "label": "No Response"
  },
  {
    "id": null,
    "value": "ACK_277_A",
    "label": "Accepted"
  },
  {
    "id": null,
    "value": "ACK_277_D",
    "label": "Rejected"
  },
  {
    "id": null,
    "value": "D",
    "label": "Denied"
  },
  {
    "id": null,
    "value": "RS",
    "label": "Re-Submitted"
  },

  {
    "id": null,
    "value": "P",
    "label": "Paid"
  },
]
export const allPlans = [
  { text: "Please Select", value: "" },
  { text: "Primary", value: "P" },
  { text: "Secondary", value: "S" },
  { text: "Tertiary", value: "T" },
  { text: "Other", value: "O" },
]

export const gendersSvg = {
  Male: "/img/icons/male.svg",
  Female: "/img/icons/female.svg",
}

export const FIRST_DATE_OF_FIRST_MONTH = moment().startOf('year').startOf('month').format('YYYY-MM-DD')
export const TODAY_DATE = moment().format('YYYY-MM-DD')
export const PREVIOUS_WEEK_DATE = moment(TODAY_DATE).subtract(1, 'weeks').format('YYYY-MM-DD');
