import { createSlice } from '@reduxjs/toolkit'

const initial_State = {
    userinfo: {
        token: null,
        isProvider: null,
        userEmail: "",
        userTimeOut: null,
        userId: null,
        userName: "",
        userType: "",
        roleId: "",
        roleName: "",
        eRxEmail: null,
        eRxId: null,
        locationID: null,
        providerID: null,
        encounterSettingId: null

    },
    practiceDetails: {
        address1: "",
        city: "",
        clientDetailId: null,
        contextDetailId: null,
        id: null,
        isPracticeRights: null,
        name: null,
        officePhoneNum: null,
        state: null,
        zipCode: null,
    }


}



export const UserAuthSlice = createSlice({
    name: 'UserAuthSlice',
    initialState: initial_State,
    reducers: {
        signin: (state, action) => {
            return {
                ...state,
                userinfo: {
                    ...state.userinfo,
                    token: action.payload
                }
            }
        },
        refresh: (state, action) => {
            return {
                ...state,
                userinfo: {
                    ...state.userinfo,
                    ...action.payload.newTokens
                }
            }
        },
        signout: (state) => {
            return {
                ...state,
                userinfo: {
                    ...state.userinfo,
                    token: null
                }
            }
        },
        addPracticeDetails: (state, action) => {
            return {
                ...state,
                practiceDetails: {
                    ...state.practiceDetails,
                    ...action.payload
                }
            }
        },
        addUserInfo: (state, action) => {
            return {
                ...state,
                userinfo: {
                    ...state.userinfo,
                    ...action.payload
                }
            }
        },
        userAuthAction: (state, action) => {
            return {
                ...state,
                practiceDetails: action.payload.practiceDetails,
                userinfo: action.payload.userInfo
            }
        },
    },

})

// Action creators are generated for each case reducer function
export const { signin, signout, addPracticeDetails, addUserInfo, userAuthAction } = UserAuthSlice.actions

export default UserAuthSlice.reducer
