import { createSlice } from "@reduxjs/toolkit";
import GlobalNavigation from "../../Common/GlobalNavigationObj";
import { toast } from "react-toastify";

const initialState = {
  sidebar: true,
  // mainTab: { name: "dashboard", key: 1, right: true },
  // subTab: { name: "", key: 0, right: true },
  // innerSubTab: { name: "", key: 0, right: true },
  patients: [
    {
      active: true,
      id: 0,
      subTab: "",
      name: "Search",
    },
  ],
  charges2: [
    {
      active: true,
      id: 0,
      subTab: "",
      name: "Search",
      data: { claimStatus: "", patientId: "" },
    },
  ],
  insurancePayment: {
    pID: 0,
    InsData: {
      paymenttype: "ERA_EOB",
      payerid: "",
      payerName: "",
      checknumber: "",
      checkdatefrom: "",
      checkdateto: "",
      depositdatefrom: "",
      depositdateto: "",
      poststatus: "NeedPosting",
      entrydatefrom: "",
      entrydateto: "",
      receiveddatefrom: "",
      receiveddateto: "",
      visitid: "",
      posteddatefrom: "",
      posteddateto: "",
      dateType: "Check Date",
    },
    rowData: null
  },
  submission: [
    {
      parentKey: 7,
      parentName: "submission",
      key: 8,
      name: "submission",
      title: "Submission",
      active: true,
      data: { activeTab: "unbilled", visitValidation: "all" },
    },
    {
      parentKey: 7,
      parentName: "submission",
      key: 9,
      name: "submissionLog",
      title: "Submission Log",
      active: false,
      data: {},
    },
    {
      parentKey: 7,
      parentName: "submission",
      key: 10,
      name: "ediResponseLog",
      title: "EDI Response Log",
      active: false,
      data: {},
    },
    // { parentKey: 7, parentName: "submission", key: 11, name: "patientStatement", title: "Patient Statement", active: false, data: {} },
  ],
};

export const TabsSlice = createSlice({
  name: "tab",
  initialState,
  reducers: {
    activeMainTab: (state, action) => {
      state["mainTab"] = action.payload;
    },

    activeSubTab: (state, action) => {
      if (!state[action.payload.parentKey]) {
        state[action.payload.parentName] = [
          { ...action.payload, active: true },
        ];
      } else {
        state[action.payload.parentName] = [
          state[action.payload.parentName].map((item) => (item.active = false)),
          { ...action.payload, active: true },
        ];
      }
    },
    activeSubmissionSubTab: (state, action) => {
      const updatedSubmissionTabs = state.submission.map((tab) => ({
        ...tab,
        active: tab.key === action.payload.subtab.key ? true : false,
        data:
          tab.key === action.payload.subtab.key
            ? action.payload.data
            : tab.data,
      }));
      return {
        ...state,
        mainTab: action.payload.maintab,
        submission: updatedSubmissionTabs,
      };
    },

    swtichTab: (state, action) => {
      const Data = action.payload;
      let parent;
      let subTab;
      let innerSubTab;
      if (Data.parentName && Data.subTabName && Data.innerSubTabName) {
        parent = {
          ...GlobalNavigation.find((tab) => tab.name === Data.parentName),
        };
        subTab = {
          ...parent.subTabs.find((tab) => tab.name === Data.subTabName),
        };
        innerSubTab = {
          ...subTab.subTabs.find((sTab) => sTab.name === Data.innerSubTabName),
        };

        delete parent.subTabs;
        delete subTab.subTabs;
        delete innerSubTab.subTabs;

        state["mainTab"] = parent;
        state["mainTab"]["parentData"] = Data?.parentData;
        state[action.payload.parentName] = [
          {
            ...subTab,
            parentName: parent.name,
            parentKey: parent.key,
            subTabData: Data?.subTabData,
            active: true,
          },
        ];
        state[action.payload.subTabName] = [
          {
            ...innerSubTab,
            parentName: subTab.name,
            parentKey: subTab.key,
            innerSubData: Data?.innerSubData,
            active: true,
          },
        ];
      } else if (Data.parentName && Data.subTabName) {
        parent = {
          ...GlobalNavigation.find((tab) => tab.name === Data.parentName),
        };
        subTab = {
          ...parent.subTabs.find((tab) => tab.name === Data.subTabName),
        };

        delete parent.subTabs;
        delete subTab.subTabs;
        state["mainTab"] = parent;
        state[action.payload.parentName] = [
          {
            ...subTab,
            parentName: parent.name,
            subTabData: Data?.subTabData,
            parentData: Data?.parentData,
            parentKey: parent.key,
            active: true,
          },
        ];
      } else if (Data.parentName) {
        parent = {
          ...GlobalNavigation.find((tab) => tab.name === Data.parentName),
        };
        delete parent.subTabs;
        state["mainTab"] = parent;
        state["mainTab"]["parentData"] = Data?.parentData;
      }
    },
    toggleSidebar: (state, action) => {
      return {
        ...state,
        sidebar: action.payload,
      };
    },
    addPatient: (state, action) => {
      const oldPatients = state.patients;
      // first check if patient is already added in array or not
      const patientExist = oldPatients.some(
        (pat) => pat.id === action.payload.id
      );
      let updatedPatients = [];
      if (oldPatients.length <= 5) {
        if (patientExist) {
          updatedPatients = oldPatients.map((pat) => ({
            ...pat,
            subTab:
              pat.id === action.payload.id ? action.payload.subTab : pat.subTab,
            active: pat.id === action.payload.id ? true : false,
          }));
        } else {
          updatedPatients = oldPatients.map((pat) => ({
            ...pat,
            active: false,
          }));
          updatedPatients.push(action.payload);
        }
        return {
          ...state,
          patients: updatedPatients,
          mainTab: { name: "patient", key: 2, right: true },
        };
      } else {
        toast.warning("Maximun 5 Patients can be opened");
        return {
          ...state,
        };
      }
    },
    removePatient: (state, action) => {
      // to remove all the patients and search tab to active
      if (!action.payload) {
        state.patients = [
          {
            active: true,
            id: 0,
            subTab: "",
            name: "Search",
          },
        ];
      } else {
        const updatedPatients = state.patients.filter(
          (pat) => pat.id !== action.payload
        );
        const updaateddd = updatedPatients.map((pat) => {
          if (pat.id) {
            return { ...pat, active: false };
          } else {
            return { ...pat, active: true };
          }
        });
        return {
          ...state,
          patients: updaateddd,
        };
      }
    },
    switchToPatient: (state, action) => {
      const oldPatients = state.patients;
      const updatedPatients = oldPatients.map((pat) => ({
        ...pat,
        active: pat.id === action.payload ? true : false,
      }));
      return {
        ...state,
        patients: updatedPatients,
      };
    },
    switchToPatientSubTab: (state, action) => {
      const updatedPatients = state.patients.map((pat) => {
        let obj = { ...pat };
        if (pat.active === true) {
          return { ...obj, subTab: action.payload };
        } else {
          return obj;
        }
      });
      return {
        ...state,
        patients: updatedPatients,
      };
    },
    addClaim: (state, action) => {
      const oldCharges = state.charges2;
      // first check if claim is already added in array or not
      const claimExist = oldCharges.some((pat) => pat.id === action.payload.id);
      let updatedClaims = [];
      if (oldCharges.length <= 5) {
        if (claimExist) {
          updatedClaims = oldCharges.map((charge) => ({
            ...charge,
            subTab:
              charge.id === action.payload.id
                ? action.payload.subTab
                : charge.subTab,
            active: charge.id === action.payload.id ? true : false,
          }));
        } else {
          updatedClaims = oldCharges.map((charge) => ({
            ...charge,
            active: false,
          }));
          updatedClaims.push(action.payload);
        }
        return {
          ...state,
          charges2: updatedClaims,
          mainTab: { name: "charges2", key: 33, right: true },
        };
      } else {
        toast.warning("Maximun 5 Claims can be opened");
        return {
          ...state,
        };
      }
    },
    switchToClaim: (state, action) => {
      const oldCharges = state.charges2;
      const updatedCharges = oldCharges.map((charge) => ({
        ...charge,
        active: charge.id === action.payload ? true : false,
      }));
      return {
        ...state,
        charges2: updatedCharges,
      };
    },
    removeClaim: (state, action) => {
      const updatedCharges = state.charges2.filter(
        (charge) => charge.id !== action.payload
      );
      const updaateddd = updatedCharges.map((charge) => {
        if (charge.id) {
          return { ...charge, active: false };
        } else {
          return { ...charge, active: true };
        }
      });
      return {
        ...state,
        charges2: updaateddd,
      };
    },
    changeClaimStatus: (state, action) => {
      const updatedCharges = state.charges2.map((charge) => {
        if (charge.id === action.payload.chargesData.id) {
          return { ...action.payload.chargesData, active: true };
        } else {
          return { ...charge, active: false };
        }
      });
      return {
        ...state,
        charges2: updatedCharges,
        mainTab: action.payload.mainTab,
      };
    },
    setInsurancePayment: (state, action) => {

      return {
        ...state,
        insurancePayment: action.payload.data,
        mainTab: action.payload.mainTab,
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  toggleSidebar,
  activeMainTab,
  activeSubTab,
  addPatient,
  switchToPatient,
  switchToPatientSubTab,
  addToSubTab,
  closePatient,
  removePatient,
  swtichTab,
  activeSubmissionSubTab,
  addClaim,
  switchToClaim,
  removeClaim,
  changeClaimStatus,
  setInsurancePayment
} = TabsSlice.actions;

export default TabsSlice.reducer;
